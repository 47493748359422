import React, { useLayoutEffect, useEffect} from "react";
import Navbar from "../../components/navbar/navbar";
import "./landingPage.css";
import land1 from "../../static/images/landing/land1.svg";
import land2 from "../../static/images/landing/land2.svg";
import land3 from "../../static/images/landing/land3.svg";
import dgp from "../../static/images/landing/dgp.svg";
import gx from "../../static/images/landing/gx.svg";
import { useStore } from "../../components/store/store";
import Dropdown from "../../components/DropDown/dropdown";
import gpt from "../../static/images/navbar/gpt.svg";
import { useHistory } from "react-router-dom";
import Mobmenu from "../../components/mobmenu/mobmenu";

const LandingPage = () => {
  let history = useHistory();
  const { isOpen, setOpen, navOpen, setNavOpen, mobsize, setMobsize } = useStore();

  useLayoutEffect(() => {
    function updateSize() {
      setMobsize(window.innerWidth);
      if(window.innerWidth > 900){
        setNavOpen(false)
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [mobsize]);

  return (
    <div className="landing-container">
      <Navbar />
      <div>
        {isOpen ? (
          <Dropdown />
        ) :
         !navOpen ?(
          <>
            <div className="landing-main">
              <div className="landing-head">
                if the economy is social, the currency will be influence
              </div>
              <div className="landing-text">
                EarnGPT is the AI system that allows brands, affiliates, and
                influencers to monetize their influence and IFC is the gas for
                the EarnGPT
              </div>
              <div className="landing-mob-btns">
                  <button className="landing-btn1">
                    <span>
                      <img className="landing-btn1-img" src={gpt} alt="" />
                    </span>
                    EarnGPT
                  </button>
                  <button
                    className="landing-btn2"
                    onClick={() => history.push("/buy")}
                  >
                    Buy
                  </button>
              </div>
              <div className="landing-all-icons">
                <div className="landing-icon-box">
                  <img className="landing-icon" src={land1} alt="" />
                </div>
                <div className="landing-border"> </div>
                <div className="landing-icon-box">
                  <img className="landing-icon" src={land2} alt="" />
                </div>
                <div className="landing-border"> </div>
                <div className="landing-icon-box">
                  <img className="landing-icon" src={land3} alt="" />
                </div>
              </div>
            </div>
            <div className="landing-sec2">
              <div className="sec2-left">
                <div className="sec2-left-head">Are You A DGPToken Holder?</div>
                <div className="sec2-left-desc">
                  You may be eligible to swap 1:1 into IFC. Please see
                  eligibility requirements{" "}
                  <span className="sec2-line">here.</span>
                </div>
                <div className="sec2-left-btn">Swap</div>
              </div>
              <div className="sec2-right">
                <img className="sec2-right-img" src={dgp} alt="" />
              </div>
            </div>
            <div className="landing-sec3">
              <div className="sec3-left">
                <div className="sec3-left-head">Are You A GXToken Holder?</div>
                <div className="sec3-left-desc">
                  You may be eligible to swap 1:1 into IFC. Please see
                  eligibility requirements{" "}
                  <span className="sec3-line">here.</span>
                </div>
                <div className="sec3-left-btn">Swap</div>
              </div>
              <div className="sec3-right">
                <img className="sec3-right-img" src={gx} alt="" />
              </div>
            </div>
          </>
        )
        :(
          <Mobmenu />
        )
        }
      </div>
    </div>
  );
};

export default LandingPage;
