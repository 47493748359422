import React from "react";
import "./mobmenu.css";
import { useStore } from "../store/store";
import { useHistory } from "react-router-dom";
import gpt from "../../static/images/navbar/gpt.svg";

const Mobmenu = () => {
  let history = useHistory();
  const { isOpen, setOpen, navOpen, setNavOpen, nav, setnav } = useStore();
  return (
    <div className="mob-menu-container">
      <div className="mob-menu-box">
        <div
          className="mob-menu-each"
          onClick={() => {
            setnav("Home");
            setNavOpen(false);
          }}
          style={{
            background: nav === "Home" ? "rgba(255, 255, 255, 0.12)" : "",
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === "Space") {
              setNavOpen(!navOpen);
            }
          }}
          role="menuitem"
          tabIndex={0}
        >
          Home
        </div>
        <div
          className="mob-menu-each"
          onClick={() => {
            setnav("For Affiliates");
            setNavOpen(false);
          }}
          style={{
            background:
              nav === "For Affiliates" ? "rgba(255, 255, 255, 0.12)" : "",
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === "Space") {
              setNavOpen(!navOpen);
            }
          }}
          role="menuitem"
          tabIndex={0}
        >
          For Affiliates
        </div>
        <div
          className="mob-menu-each"
          onClick={() => {
            setnav("For Influencers");
            setNavOpen(false);
          }}
          style={{
            background:
              nav === "For Influencers" ? "rgba(255, 255, 255, 0.12)" : "",
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === "Space") {
              setNavOpen(!navOpen);
            }
          }}
          role="menuitem"
          tabIndex={0}
        >
          For Influencers
        </div>
        <div
          className="mob-menu-each"
          onClick={() => {
            setnav("For Brands");
            setNavOpen(false);
          }}
          style={{
            background: nav === "For Brands" ? "rgba(255, 255, 255, 0.12)" : "",
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === "Space") {
              setNavOpen(!navOpen);
            }
          }}
          role="menuitem"
          tabIndex={0}
        >
          For Brands
        </div>
      </div>
      <div className="mob-btns">
        <button className="landing-btn1">
          <span>
            <img className="landing-btn1-img" src={gpt} alt="" />
          </span>
          EarnGPT
        </button>
        <button className="landing-btn2" onClick={() => history.push("/buy")}>
          Buy
        </button>
      </div>
    </div>
  );
};

export default Mobmenu;
