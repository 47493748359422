import Routes from "./Routes";
import "./static/scss/master.scss";
// import "@teamforce/coins-app/dist/index.css";
import ChatsContextProvider from "./context/GlobalContext";

function App() {
  return (
    <ChatsContextProvider>
      <Routes />
    </ChatsContextProvider>
  );
}

export default App;
