import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import Web3 from "web3";
import { ChatsContext } from "../../context/GlobalContext";
import useWindowDimensions from "../../Utils/WindowSize";
import "./BuyPage.css";

import TransactionCardHome from "../../components/TransactionCardHome/TransactionCardHome";
import Navbar from "../../components/navbar/navbar";

function BuyPage() {
  const history = useHistory();
  const { chatOn, setChatOn, setCoinETH } = useContext(ChatsContext);
  const { height } = useWindowDimensions();

  const [openModal, setOpenModal] = useState(false);
  const [coinList, setCoinList] = useState([]);
  const [coin, setCoin] = useState();
  const [coinBuy, setCoinBuy] = useState();
  const [slider, setSlider] = useState(17);

  useEffect(() => {
    axios
      .get("https://comms.globalxchange.com/coin/vault/get/all/coins")
      .then(({ data }) => {
        if (data.status) {
          setCoinList(data.coins);
          const cCoin = data.coins.filter(
            (coin) => coin.coinSymbol === "ETH"
          )[0];
          setCoin(cCoin);
          setCoinETH(cCoin);
          setCoinBuy(cCoin);
        }
      });
  }, []);
  const [isConnected, setIsConnected] = useState(false);
  const [ethBalance, setEthBalance] = useState("");
  const detectCurrentProvider = () => {
    let provider;
    if (window.ethereum) {
      provider = window.ethereum;
    } else if (window.web3) {
      provider = window.web3.currentProvider;
    } else {
      console.log("Non-ethereum browser detected. You should install Metamask");
    }
    return provider;
  };
  // To detect network change
  useEffect(() => {
    const currentProvider = detectCurrentProvider();
    if (currentProvider) {
      currentProvider.on("chainChanged", (chainId) => {
        window.location.reload();
      });
    }
  }, []);

  // To detect account change
  useEffect(() => {
    const currentProvider = detectCurrentProvider();
    if (currentProvider) {
      currentProvider.on("accountsChanged", (accounts) => {
        window.location.reload();
      });
    }
  }, []);

  //Web3 connection to MetaMask
  const onConnect = async () => {
    try {
      const currentProvider = detectCurrentProvider();
      if (currentProvider) {
        await currentProvider.request({ method: "eth_requestAccounts" });
        const web3 = new Web3(currentProvider);
        const userAccount = await web3.eth.getAccounts();
        const account = userAccount[0];
        let ethBalance = await web3.eth.getBalance(account);
        ethBalance = web3.utils.fromWei(ethBalance, "ether");
        //check for network
        const networkId = await web3.eth.net.getId();
        if (networkId !== 1) {
          await currentProvider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "0x1" }],
          });
        }
        // window.alert(network);
        setEthBalance(ethBalance);
        window.alert(`Connected to ${currentProvider.selectedAddress}`);
        setIsConnected(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
      <div className="buyPage" style={{ height }}>
        <Navbar/>
        <div className="pageContents">
          <div className="cardWrapper">
            <TransactionCardHome
              coin={coinBuy}
              setCoin={setCoinBuy}
              coinList={coinList}
            />
          </div>
        </div>
      </div>
  );
}

export default BuyPage;
