import React, { useContext, useEffect, useState } from "react";
// import { MainLayout, MainNavbar, FooterFlipCards } from "@teamforce/coins-app";
import { useHistory } from "react-router";
import axios from "axios";
// import moment from "moment";
import Web3 from "web3";
import logo from "../static/images/logos/influenceCoin.svg";
import logoIcon from "../static/images/logos/influenceIcon.svg";
// import ChatsIoComponentM from "../components/ChatsIoComponent/ChatsIoComponent";
import { ChatsContext } from "../context/GlobalContext";
import useWindowDimensions from "../Utils/WindowSize";
import profiles from "../static/images/logos/profiles.svg";
import oppertunities from "../static/images/logos/oppertunities.svg";
import terminals from "../static/images/logos/terminals.svg";
import virtualProspectus from "../static/images/logos/virtualProspectus.svg";
import locationPin from "../static/images/slider/pin.svg";

import TransactionCardHome from "../components/TransactionCardHome/TransactionCardHome";
import CoinSelectModal from "../components/SelectCountry/CoinSelectModal";

function HomePage() {
  const history = useHistory();
  const { chatOn, setChatOn, setCoinETH } = useContext(ChatsContext);
  const { height } = useWindowDimensions();

  const [openModal, setOpenModal] = useState(false);
  const [coinList, setCoinList] = useState([]);
  const [coin, setCoin] = useState();
  const [coinBuy, setCoinBuy] = useState();
  const [slider, setSlider] = useState(17);

  useEffect(() => {
    axios
      .get("https://comms.globalxchange.com/coin/vault/get/all/coins")
      .then(({ data }) => {
        if (data.status) {
          setCoinList(data.coins);
          const cCoin = data.coins.filter(
            (coin) => coin.coinSymbol === "ETH"
          )[0];
          setCoin(cCoin);
          setCoinETH(cCoin);
          setCoinBuy(cCoin);
        }
      });
  }, []);
  const [isConnected, setIsConnected] = useState(false);
  const [ethBalance, setEthBalance] = useState("");
  const detectCurrentProvider = () => {
    let provider;
    if (window.ethereum) {
      provider = window.ethereum;
    } else if (window.web3) {
      provider = window.web3.currentProvider;
    } else {
      console.log("Non-ethereum browser detected. You should install Metamask");
    }
    return provider;
  };
  // To detect network change
  useEffect(() => {
    const currentProvider = detectCurrentProvider();
    if (currentProvider) {
      currentProvider.on("chainChanged", (chainId) => {
        window.location.reload();
      });
    }
  }, []);

  // To detect account change
  useEffect(() => {
    const currentProvider = detectCurrentProvider();
    if (currentProvider) {
      currentProvider.on("accountsChanged", (accounts) => {
        window.location.reload();
      });
    }
  }, []);

  //Web3 connection to MetaMask
  const onConnect = async () => {
    try {
      const currentProvider = detectCurrentProvider();
      if (currentProvider) {
        await currentProvider.request({ method: "eth_requestAccounts" });
        const web3 = new Web3(currentProvider);
        const userAccount = await web3.eth.getAccounts();
        const account = userAccount[0];
        let ethBalance = await web3.eth.getBalance(account);
        ethBalance = web3.utils.fromWei(ethBalance, "ether");
        //check for network
        const networkId = await web3.eth.net.getId();
        if (networkId !== 1) {
          await currentProvider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "0x1" }],
          });
        }
        // window.alert(network);
        setEthBalance(ethBalance);
        window.alert(`Connected to ${currentProvider.selectedAddress}`);
        setIsConnected(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
      <div className="landingPage" style={{ height }}>

        <div className="pageContents">
          <div className="titleDesc">
            <div className="titleSm">Your Network Is Your</div>
            <div className="title">Net-Worth. Literally</div>
            <p>
              Influence (IFC) Is The Worlds First Cryptocurrency That Quantifies
              The Value Of Your Network.
            </p>
            <div className="btWrap">
              <div className="head">
                <span>Today</span>
                
              </div>
              <div className="btRates">
                <div
                  className="coin"
                  role="presentation"
                  onClick={() => setOpenModal(true)}
                  onKeyDown={() => setOpenModal(true)}
                >
                  <img src={coin?.coinImage} alt="" />
                  <span>{coin?.coinSymbol}</span>
                </div>
                <span className="value">
                  {coin?.symbol}0.0004
                  <small>+(2.31%)</small>
                </span>
              </div>
            </div>
            <div className="sliderWrapper">
              <img
                src={locationPin}
                alt=""
                className="locPin"
                style={{
                  left: `${slider}%`,
                  right: `${100 - slider}%`,
                }}
              />
              <input
                type="range"
                className="range"
                min={0}
                max={100}
                onChange={(e) => setSlider(e.target.value)}
              />
              <div className="labels">
                <div className="creation">Creation</div>
                <div className="launch">Launch</div>
                <div className="lock">Lock</div>
              </div>
              <div className="redeem">Redeem</div>
            </div>
          </div>

          <div className="cardWrapper">
            <div className="connect">
              {isConnected ? (
                <p className="connectTitle">

                </p>
              ) : (
                <p className="connectTitle">
                  Connect your wallet to get started
                </p>
              )}
              {isConnected ? (
                <button className="connectBtn">Connected</button>
              ) : (
                <button className="connectBtn" onClick={onConnect}>
                  Connect
                </button>
              )}
            </div>

            <TransactionCardHome
              coin={coinBuy}
              setCoin={setCoinBuy}
              coinList={coinList}
            />
          </div>
        </div>
        {/* <FooterFlipCards
          footerCards={[
            {
              label: "Prospectus",
              logo: virtualProspectus,
            },
            {
              label: "Trading",
              logo: terminals,
            },
            {
              label: "Partnerships",
              logo: oppertunities,
            },
            {
              label: "Background",
              logo: profiles,
            },
          ]}
        /> */}
      </div>
    //   {openModal && (
    //     <CoinSelectModal
    //       coinList={coinList}
    //       setCoin={setCoin}
    //       onClose={() => setOpenModal(false)}
    //     />
    //   )}
    // </MainLayout>
  );
}

export default HomePage;
