import React, { useContext, useEffect, useState } from "react";
import ifcIcon from "../../../static/images/logos/influenceIcon.svg";
import GXTIcon from "../../../static/images/logos/asset_GXT_new.png";
import { ChatsContext } from "../../../context/GlobalContext";
import {
  GXTToken,
  IFCToken,
  PurchaseSmartContract,
  SwapSmartContract,
} from "../../../config";
import './Swap.css'


//web3 Imports
import { ethers } from "ethers";
import Web3 from "web3";
import detectEthereumProvider from '@metamask/detect-provider';


export default function SwapIFC({ coin }) {
  //web3 States
  const [GXTInput, setGXTInput] = useState(0);
  const [GXTbalanceInfo, setGXTBalanceInfo] = useState({
    balance: "-",
  });
  const [IFCbalanceInfo, setIFCBalanceInfo] = useState({
    balance: "-",
  });
  const [etherInput, setEtherInput] = useState(1);
  const [isConnected, setIsConnected] = useState(true);
  const [ethBalance, setEthBalance] = useState("");
  const [connectedAddress, setConnectedAddress] = useState("");

  const provider = detectEthereumProvider();
  if (provider) {
    // From now on, this should always be true:
    // provider === window.ethereum
  } else {
    console.log('Please install MetaMask!');
  }
  const getMyBalancetotal = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send("eth_requestAccounts", []);
    const GXT = new ethers.Contract(GXTToken.address, GXTToken.abi, provider);
    const IFC = new ethers.Contract(IFCToken.address, IFCToken.abi, provider);
    const signer = await provider.getSigner();
    const signerAddress = await signer.getAddress();
    const GXTbalance = await GXT.balanceOf(signerAddress);
    const IFCbalance = await IFC.balanceOf(signerAddress);
    const network = await provider.getNetwork();
    const chainId = network.chainId;
    console.log(chainId);
    setGXTBalanceInfo({
      balance: String(GXTbalance),
    });

    setIFCBalanceInfo({
      balance: String(IFCbalance),
    });
  };

  useEffect(() => {
    getMyBalancetotal();
    console.log("GXTbalance", GXTbalanceInfo);
  }, []);
  const detectCurrentProvider = () => {
    let provider;
    if (window.ethereum) {
      provider = window.ethereum;
    } else if (window.web3) {
      provider = window.web3.currentProvider;
    } else {
      console.log("Non-ethereum browser detected. You should install Metamask");
    }
    return provider;
  };
  const handleApprove = async (e) => {
    e.preventDefault();
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send("eth_requestAccounts", []);
    const signer = await provider.getSigner();
    const erc20 = new ethers.Contract(GXTToken.address, GXTToken.abi, signer);
    const GXTCustomerInput = ethers.utils.parseUnits(GXTInput, 18)
    console.log("b", GXTCustomerInput);
    if (GXTCustomerInput <= GXTbalanceInfo.balance) {
      const tx = await erc20.approve(
        SwapSmartContract.address,
        GXTCustomerInput
      );
      console.log(`Transaction hash: ${tx.hash}`);
    } else {
      alert(
        `Amount must be less than your balance, Your GXT Balance is ${GXTbalanceInfo.balance} GXT`
      );
    }
  };

  const swapTokens = async (e) => {
    try {
      e.preventDefault();
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      const gxtContract = new ethers.Contract(GXTToken.address, GXTToken.abi, signer);
      let gxtCustomerInput = ethers.utils.parseUnits(GXTInput, 18);
      gxtCustomerInput = gxtCustomerInput.toString()
      const approveTx = await gxtContract.approve(SwapSmartContract.address, gxtCustomerInput);
      console.log(`Approve transaction hash: ${approveTx.hash}`);
      const approveReceipt = await approveTx.wait();
      console.log("Approve transaction mined. Receipt:", approveReceipt);
      if (approveReceipt.status === 1) {
        const swapContract = new ethers.Contract(SwapSmartContract.address, SwapSmartContract.abi, signer);
        const swapTx = await swapContract.Swap(gxtCustomerInput);
        console.log(`Swap transaction hash: ${swapTx.hash}`);
        const swapReceipt = await swapTx.wait();
        console.log("Swap transaction mined. Receipt:", swapReceipt);
        console.log('Tokens swapped successfully');
        window.alert(`Swap transaction hash: ${swapTx.hash}`)
      } else {
        console.error('Approve transaction failed');
      }
    } catch (error) {
      console.error(error);
    }
  };


  // const swapTokens = async (_fromToken, _toToken, _fromAmount) => {
  //   try {
  //     // First handle approve
  //     const provider = new ethers.providers.Web3Provider(window.ethereum);
  //     await provider.send("eth_requestAccounts", []);
  //     const signer = await provider.getSigner();
  //     const erc20 = new ethers.Contract("0xc27F1983660A53E6a54C15eAeDbE032578aa57E5", IFCToken.abi, signer);
  //     let z = etherInput * 0.0004000000000
  //     z = z.toString()
  //     const GXTCustomerInput = ethers.utils.parseEther(z, 18)
  //     const tx = await erc20.approve(IFCToken.address, GXTCustomerInput);
  //     console.log(`Approve transaction hash: ${tx.hash}`);
  //     // Wait for approve transaction to be mined
  //     const approveReceipt = await tx.wait();
  //     console.log("Approve transaction mined. Receipt:", approveReceipt);
  //     // Only proceed with swapping after approve transaction is mined
  //     if (approveReceipt.status === 1) {
  //       const contract = new ethers.Contract(contractAddress, contractABI, signer);
  //       const transaction = await contract.swapTokens("0xc27F1983660A53E6a54C15eAeDbE032578aa57E5", "0xBC2D5306db2D7b70681Ec68Aa81DF8056cCcc2C9", "4000");
  //       console.log('Tokens swapped successfully');
  //     } else {
  //       console.error('Approve transaction failed');
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  //Web3 connection to MetaMask
  const onConnect = async () => {
    try {
      const currentProvider = detectCurrentProvider();
      if (currentProvider) {
        await currentProvider.request({ method: "eth_requestAccounts" });
        const web3 = new Web3(currentProvider);
        const userAccount = await web3.eth.getAccounts();
        const account = userAccount[0];
        let ethBalance = await web3.eth.getBalance(account);
        ethBalance = web3.utils.fromWei(ethBalance, "ether");
        //check for network
        const networkId = await web3.eth.net.getId();
        if (networkId !== 1) {
          await currentProvider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "0x1" }],
          });
        }
        setEthBalance(ethBalance);
        window.alert(`Connected to ${currentProvider.selectedAddress}`);
        setConnectedAddress(currentProvider.selectedAddress);
        setIsConnected(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleChangeEth = (event) => {
    setGXTInput(event.target.value);
  };
  return (
    <div className="SwapCard">
      <div className="contents">
        {/* <div className="group">
          <label className="inpWraper">
            <input
              type="text"
              placeholder={`${GXTbalanceInfo.balance/10**18}`}
              disabled
            />
            <div className="coin">
              <img src={GXTIcon} alt="gxt" />
              <span>GXT</span>
            </div>
          </label>
        </div> */}
        {/* <div className="group">
          <label className="inpWraper">
            <input
              type="text"
              placeholder={`${IFCbalanceInfo.balance/10**18}`}
              disabled
            />
            <div className="coin">
              <img src={ifcIcon} alt="gxt" />
              <span>IFC</span>
            </div>
          </label>
        </div> */}
        <div className="group">
          <div className="inpWraper">
            <input
              type="text"
              placeholder={`1`}
              value={GXTInput}
              onChange={handleChangeEth}
              name="ether"
              className="inputcontainer aactive"
            />
            <div className="coinContainer">
              <p>GXT to IFC</p>
            </div>
          </div>
        </div>
      </div>
      {isConnected ?
          <div>
            <button className="btBuyButton" onClick={onConnect}>Connect Wallet</button>
          </div> :
          <div>
            <p className="label labelActive" style={{ height: '20px', lineHeight: '20px' }}>Connected Wallet: {connectedAddress}</p>
            <button className="btBuyButton" onClick={swapTokens} >Swap</button>
          </div>}
      {/* <div className="">
        <p className="label labelActive" style={{ height: '20px', lineHeight: '20px' }}>Connected Wallet: {connectedAddress}</p>
        <button className="btBuyButton" onClick={swapTokens} >Swap</button>
      </div> */}
    </div>
  );
}
