import React, { createContext, useState } from "react";


export const ChatsContext = createContext();
function ChatsContextProvider({ children }) {
  const [chatOn, setChatOn] = useState(false);
  const [tabSelected, setTabSelected] = useState("Buy");
  const [coinETH, setCoinETH] = useState();
  const TokenSmartContracts = [
    {
      address: "0x60c87297A1fEaDC3C25993FfcadC54e99971e307",
      tokenName: "GX Token",
      tokenSymbol: "GXT",
      tokenDecimals: 18,
      totalSupply: "350000000000000000000000000",
    },
    {
      address: "0x9e077d6c9c7bc09e09ef3701d9554fc221543caa",
      tokenName: "IF Coin",
      tokenSymbol: "IFC",
      tokenDecimals: 18,
      totalSupply: "350000000000000000000000000",
    },
  ];
  const PurchaseSmartContracts = [
    {
      address: "0xec946d41e4f427cf41c5ce27e4fa8040721ffd31",
    },
  ];
  const SwapSmartContracts = [
    {
      address: "0x8b35de2fd8a71d9f2895a82239547d72ecb29392",
    },
  ];

  const value = {
    chatOn,
    setChatOn,
    tabSelected,
    setTabSelected,
    coinETH, setCoinETH,
  };

  return (
    <ChatsContext.Provider value={value}>{children}</ChatsContext.Provider>
  );
}

export default ChatsContextProvider;

