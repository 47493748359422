import GXTabi from "../src/SmartContract/abi/GXT.json"
import IFCabi from "../src/SmartContract/abi/IFC.json"
import Purchaseabi from "../src/SmartContract/abi/ethSwappingContractAbi.json"
import masterAbi from "../src/SmartContract/abi/masterAbi.json"
import Swapabi from "../src/SmartContract/abi/GXTtoIFCAbi.json"

const GXTToken = {
  address: "0x60c87297A1fEaDC3C25993FfcadC54e99971e307",
  tokenName: "GX Token",
  tokenSymbol: "GXT",
  tokenDecimals: 18,
  totalSupply: "350000000000000000000000000",
  abi: GXTabi,
};

const IFCToken = {
  address: "0x9e077d6c9c7bc09e09ef3701d9554fc221543caa",
  tokenName: "IF Coin",
  tokenSymbol: "IFC",
  tokenDecimals: 18,
  totalSupply: "350000000000000000000000000",
  abi: IFCabi,
};

const PurchaseSmartContract = {
  address: "0xec946d41e4f427cf41c5ce27e4fa8040721ffd31",
  abi: Purchaseabi,
};

const SwapSmartContract = {
  address: "0x8b35de2fd8a71d9f2895a82239547d72ecb29392",
  abi: Swapabi,
};


const masterSmartContract = {
  address:"0x448fA4bEc6be13195b303935c3C0ECE93846a6E9",
  abi: masterAbi,
}


export { GXTToken, IFCToken, PurchaseSmartContract, SwapSmartContract, masterSmartContract };
