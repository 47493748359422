import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import HomePage from "./pages/HomePage";
import BuyPage from "./pages/BuyPage/BuyPage";
import LandingPage from "./pages/landingPage/landingPage";

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route path="/buy" component={BuyPage} />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
