import create from 'zustand';

export const useStore = create((set) => ({
  isOpen: false,
  setOpen: (isOpen) => set({ isOpen }),
  navOpen: false,
  setNavOpen: (navOpen) => set({ navOpen }),
  mobsize: 0,
  setMobsize: (mobsize) => set({ mobsize }),
  nav: "Home",
  setnav: (nav) => set({ nav }),
}));