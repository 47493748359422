import React from "react";
import { useHistory } from "react-router-dom";
import "./navbar.css";
import mainlogo from "../../static/images/navbar/mainlogo.svg";
import downarrow from "../../static/images/navbar/downarrow.svg";
import gpt from "../../static/images/navbar/gpt.svg";
import ham from "../../static/images/navbar/ham.svg";
import cross from "../../static/images/navbar/cross.svg";
import { useStore } from "../store/store";

const Navbar = () => {
  let history = useHistory();
  const { isOpen, setOpen, navOpen, setNavOpen } = useStore();
  return (
    <div className="nav-container">
      <div className="nav-left">
        <div className="nav-logo-box">
          <img className="nav-logo" src={mainlogo} alt="" />
        </div>
        <button
          className="drop-box"
          onClick={() => setOpen(!isOpen)}
        >
          <div className="drop-menu-box">
            <button className="drop-menu">
              BY&nbsp;<span className="drop-menu-head">AIR</span>
            </button>
            <div
              className="drop-img-box"
              style={{ transform: isOpen ? "rotate(180deg)" : "" }}
            >
              <img className="drop-img" src={downarrow} alt="" />
            </div>
          </div>
        </button>
      </div>
      <div className="nav-right">
        <div className="nav-menu-box">
          <div className="nav-menu">For Brands</div>
          <div className="nav-menu">For Influencers</div>
          <div className="nav-menu">For Affiliates</div>
        </div>
        <div className="nav-buttons">
          <button className="nav-btn1">
            <span>
              <img className="nav-btn1-img" src={gpt} alt="" />
            </span>
            EarnGPT
          </button>
          <button className="nav-btn2" onClick={() => history.push("/buy")}>
            Buy
          </button>
        </div>
        <div
          className="nav-ham-box"
          onClick={() => setNavOpen(!navOpen)}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === "Space") {
              setNavOpen(!navOpen);
            }
          }}
          role="menuitem"
          tabIndex={0}
        >
          <img src={navOpen ? cross : ham} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
